import React, { useState } from 'react'
import { AppBar, Box, Container, CssBaseline, Grid, Slide, Toolbar, useScrollTrigger } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import yellow from '@material-ui/core/colors/yellow'
import logo from './assets/images/ls_logo.svg'
import Sidebar from './Components/Sidebar';
import Content from './Components/Content';
import {
  BrowserRouter as Router, Route,
} from "react-router-dom";


function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const theme = createMuiTheme({
  palette: {
    primary: yellow,
  },
});

const reload = () => window.location.reload();

function App() {

  return (
    <Router>
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <HideOnScroll>
            <AppBar>
              <Toolbar>
                <img src={logo} width={30} alt="logo" />
                <h2 className="ml-3 logo-text">lawstud.io<span className="code light ml-3">{ '</> API' }</span></h2>
              </Toolbar>
            </AppBar>
          </HideOnScroll>
          {/* <Toolbar /> */}
          <Box className="v-container app">
            <Grid container direction="row" className="v-container">
              <Grid item xs={3} className="column sidebar">
                <Sidebar />
              </Grid>
              <Grid item xs={9} className="column content">
                <Content />
              </Grid>
            </Grid>
            <div className="banners hover">
              <a target='_blank' rel='noreferrer' href="https://www.digitalocean.com/?refcode=4e207fc97dd9&utm_campaign=Referral_Invite&utm_medium=Referral_Program&utm_source=badge"><img src="https://web-platforms.sfo2.digitaloceanspaces.com/WWW/Badge%203.svg" alt="DigitalOcean Referral Badge" /></a>
            </div>
          </Box>
        </ThemeProvider>
      </React.Fragment>
    </Router>
  );
}

export default App;
