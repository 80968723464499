import authentication from './authentication.json'
import templates from './templates.json'
import not_found from './not_found.json'
import intro from './intro.json'
import documents from './documents.json'
import variables from './variables.json'
import categories from './categories.json'
import v2_guide from './v2_guide.json'
import jurisur from './jurisur_specific.json'

const pages = {
  templates,
  authentication,
  introduction: intro,
  documents,
  variables,
  categories,
  v2_guide,
  jurisur
}

const getPage = (page) => {
  if(!page) {
    return intro
  }
  if(pages[page]) {
    return pages[page]
  }
  return not_found
}

export { getPage }